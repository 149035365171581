@media screen and (max-width: 825px) {
    .column-container {
        min-width: 100% !important;;
    }
}

.of {
    filter: brightness(0.95);
}

@keyframes pulse {
    0% {
        color: black;
        transform: scale(1);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        color: red;
        transform: scale(1);
    }
}

.pulse {
    animation: pulse 1s alternate-reverse infinite;
}

.column-container {
    min-width: 350px;
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
}

.board-list {
    border: solid 1px #cccccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
}

.board-card {
    min-height: 86px;
    padding: 20px 10px;
    border-bottom: solid 1px #cccccc;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: move;
    background: white;
    font-size: 14px;
}

.board-group {
    padding: 20px 10px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;

    .group-title {
        font-size: 16px;
        font-weight: 500;

        div {
            display: flex;

            .group-total {
                justify-content: center;
                min-width: 150px;
                padding: 10px;
                border-radius: 5px 5px 0 0;
                border: solid 1px #cccccc;
                border-bottom: 0;
            }
        }
    }

    .group-content {
        contain: content;
        transition: height 1s ease-in-out;
        min-height: 35px;
        border: solid 1px #cccccc;
    }

    .group-content.hidden {
        min-height: unset;
        height: 1px;
    }
}
.noh:hover {
    cursor: unset !important;
}
.c {
    cursor: pointer !important;
}
.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
    background: var(--theme-petrol-hover);
}

.board-list.cdk-drop-list-dragging > .no-content {
    display: none;
}

.empty-container {
    border-style: dashed !important;
}


.board-card:last-child {
    border: none;
}

.board-list.cdk-drop-list-dragging .board-card:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.board {
    padding: 16px;
    // max-width: 100%;
    // overflow-x: auto;
}

.m-w-50 {
    max-width: 50%;
}
