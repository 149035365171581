/* You can add global styles to this file, and also import other style files */
// Global vendors
@import 'material-design-icons/iconfont/material-icons.css';
@import 'highlight.js/styles/googlecode.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'socicon/css/socicon.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

// Global fonts
@import 'socicon/css/socicon.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import './assets/plugins/flaticon/flaticon.css';
@import './assets/plugins/flaticon2/flaticon.css';
@import './assets/plugins/keenthemes-icons/font/ki.css';

// Metronic styles
@import './assets/sass/style.angular.scss';
@import './assets/sass/vendors/angular/material/angular-material';
@import './assets/sass/vendors/angular/material/include';

// Default Layout themes
@import './assets/sass/themes/layout/header/base/light.scss';
@import './assets/sass/themes/layout/header/menu/light.scss';
@import './assets/sass/themes/layout/brand/dark.scss';
@import './assets/sass/themes/layout/aside/dark.scss';

@import './assets/plugins/keenicons/duotone/style.css';
@import './assets/plugins/keenicons/outline/style.css';
@import './assets/plugins/keenicons/solid/style.css';

// Brand themes
.brand-dark {
    @import './assets/sass/themes/layout/brand/dark.scss';
}

.brand-light {
    @import './assets/sass/themes/layout/brand/light.scss';
}

//hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table-wrapper-dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

.dropdown-fluid {
    height: 100%;

    .topbar-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.mat-dialog-container {
    padding: 0 !important;
}

.cdk-overlay-connected-position-bounding-box {
    z-index: 99999 !important;
}
