//
// Brand Dark theme
//

// Initialization of global variables, mixins and functions
@import '../../../init';

// Variables
$brand-bg: var(--theme-pretol);
$brand-icon-color: #3c3c3b;
$brand-icon-color-hover: #5e5e5e;

$header-mobile-bg: var(--theme-pretol);
$header-mobile-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
$header-mobile-icon-color: #3c3c3b;
$header-mobile-icon-color-hover: #5e5e5e;

// Desktop Mode

.brand {
    background-color: $brand-bg;
    box-shadow: none;

    // Button
    .btn {
        i {
            color: $brand-icon-color;
        }

        .svg-icon {
            @include svg-icon-color($brand-icon-color);
        }

        &.active,
        &:hover {
            .svg-icon {
                @include svg-icon-color($brand-icon-color-hover);
            }

            i {
                color: $brand-icon-color-hover;
            }
        }
    }
}


// Tablet & Mobile Modes
// @media screen and (max-width: 767.98px) {
//     #kt_aside_mobile_toggle {
//         display: block;
//     }
// }

.header-mobile {
    background-color: $header-mobile-bg;
    box-shadow: $header-mobile-shadow;

    // Burger Icon
    .burger-icon {
        @include burger-icon-theme($header-mobile-icon-color, $header-mobile-icon-color-hover, $header-mobile-icon-color-hover);
    }

    // Button
    .btn {
        i {
            color: $header-mobile-icon-color;
        }

        .svg-icon {
            @include svg-icon-color($header-mobile-icon-color);
        }

        &.active,
        &:hover {
            .svg-icon {
                @include svg-icon-color($header-mobile-icon-color-hover);
            }

            i {
                color: $header-mobile-icon-color-hover;
            }
        }
    }
}

