.example-import-table > tbody {
  tr:nth-child(odd) {
    background-color: rgba(236, 236, 236, 0.84);
  }

  tr {
    td:nth-child(1) {
      font-weight: bold;
    }

    td:nth-child(3) {
      font-size: 0.96em;
    }

    .op {
      font-weight: lighter;
    }
  }
}