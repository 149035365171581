:root {
  --dial-background-color: #012551;
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 1.2rem !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-shape: 0 !important;
}

:root {
  --theme-petrol: #012551;
  --theme-petrol-hover: rgba(0, 102, 249, 0.25);

  --theme-royal: #0066f9;

  --mdc-checkbox-selected-icon-color: #012551;

  --mdc-protected-button-container-color: #012551;

  --mdc-switch-selected-hover-handle-color: #0066f9;
  --mdc-switch-selected-focus-handle-color: #012551;
  --mdc-switch-selected-pressed-handle-color: #012551;
  --mdc-switch-selected-handle-color: #012551;

  --mdc-switch-selected-hover-track-color: #01255160;
  --mdc-switch-selected-focus-track-color: #01255160;
  --mdc-switch-selected-pressed-track-color: #01255160;
  --mdc-switch-selected-track-color: #01255160;


  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #012551;
  --mdc-checkbox-selected-hover-icon-color: #0066f9;
  --mdc-checkbox-selected-icon-color: #012551;
  --mdc-checkbox-selected-pressed-icon-color: #012551;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #012551;
  --mdc-checkbox-selected-hover-state-layer-color: #0066f9;
  --mdc-checkbox-selected-pressed-state-layer-color: #012551;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mdc-switch__ripple {
  display: none;
}

.mat-drawer-container {
  background: white !important;
}

$utilities: (
        'opacity': (
                property: opacity,
                values: (
                        0: 0,
                        25: .25,
                        50: .5,
                        75: .75,
                        100: 1,
                        125: 1.25,
                )
        )
);

.mat-mdc-dialog-content {
  max-height: 80vh !important;
}

.fas-primary {
  color: var(--bs-primary) !important;
}

.view-spinner {
  display: flex !important;
  justify-content: center !important;
  padding: 25px;
}

.maxH-overflow-x {
  max-height: 95vh;
  overflow-x: auto;
}

.mdc-button__label {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .gap-2;
}

.mat-drawer-backdrop {
  position: fixed !important;
  height: 100vh;
  --mat-sidenav-scrim-color: transparent;
}

.w-filters {
  min-width: 250px;
}

.draw-filters {
  padding: 25px;
  position: fixed !important;
  height: 100vh !important;
}

.filter-top {
  top: 80px !important;
}

.compact-input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}


/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 1px !important;
}

.graph-tooltip {
  margin: 1px;
  border: 1px solid var(--theme-petrol);
  border-radius: 10px;
}

.tooltip-item {
  margin-bottom: 2px;
}

.tooltip-label {
  font-weight: bold;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}