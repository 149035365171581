//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#012551 !default;
$primary-hover:    									rgba(0, 102, 249, 0.25) !default;
$primary-light:    									#012551 !default;
$primary-inverse:  									#FFFFFF !default; 
