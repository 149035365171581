.sqd-theme-light .sqd-region {
  stroke: var(--theme-petrol);
}

.sqd-theme-light .sqd-region.sqd-selected {
  stroke: var(--theme-royal);
}

.sqd-theme-light .sqd-step-container > .sqd-label > .sqd-label-rect,
.sqd-theme-light .sqd-root-start-stop-circle,
.sqd-theme-light .sqd-step-switch > .sqd-label-primary > .sqd-label-rect {
  fill: var(--theme-petrol);
}

.sqd-theme-light .sqd-step-task .sqd-step-task-rect.sqd-selected {
  stroke: var(--theme-royal);
}

//.sqd-theme-light .sqd-placeholder .sqd-placeholder-rect {
//  fill: var(--theme-royal);
//}

.sqd-theme-light .sqd-placeholder.sqd-hover .sqd-placeholder-rect {
  fill: var(--theme-royal);
}
